import React, {useContext, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

function Logout(){
    let navigate = useNavigate();
    const { isAuthenticated, setAuthValue, signout } = useContext(AuthContext)
    useEffect(() =>{
        signout()
        navigate("/login")
    },[])
    return(
        <></>
    )
}
export default Logout