import React from 'react'
import MDInput from "components/MDInput";
import MDDropzone from "components/MDDropzone";
import MDTypography from 'components/MDTypography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


function ContactUsForm(props) {
    return (
        <>
            <MDTypography variant="h6" sx={{ mt: 5, mb: 2 }}>Section {props.index + 1}</MDTypography>
            <FormControl sx={{ mb: 2, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">Grid Size</InputLabel>
                <Select
                    sx={{ padding: 1.5 }}
                    autoWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="gridSize"
                    label="Grid Size"
                    onChange={(e) => props.onChangeEvent(e, props.index)}
                >
                    {[...Array(12)].map((nav, navi) => {
                        return (
                            <MenuItem value={navi + 1}>{navi + 1}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl><br />
            <FormControl sx={{ mb: 2, minWidth: 180 }}>
                <InputLabel id="demo-simple-select-label">Grid Container Position</InputLabel>
                <Select
                    sx={{ padding: 1.5 }}
                    autoWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="gridContainerProps"
                    label="Grid Container Position"
                    onChange={(e) => props.onChangeEvent(e, props.index)}
                >
                    <MenuItem value={"center"}>Center</MenuItem>
                    <MenuItem value={"flex-start"}>Flex Start</MenuItem>
                    <MenuItem value={"flex-end"}>Flex End</MenuItem>
                </Select>
            </FormControl>
            {/* <MDInput name="gridSize" label="Grid Size" fullWidth sx={{ my: 2, mt: 3 }} onChange={(e) => props.onChangeEvent(e, props.index)} /> */}
            <MDInput name="margin" label="Margin" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} />
            <MDInput name="padding" label="Padding" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} />
            <MDInput name="headerText" label="Header Text" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} />
            <MDInput name="title" label="Title" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} />
            <MDInput name="subtitle" label="Subtitle" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} />
            <MDInput multiline rows={5} name="description" label="Description" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} />
            {/* <MDInput name="imageurl" type="file" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} /> */}
            <MDInput name="imageurl" label="Image Link" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} />
        </>
    );
}

export default ContactUsForm;