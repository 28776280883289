import { useState, useEffect, useContext, Fragment } from "react";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React Components
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
// Images
import backgroundImage from "assets/images/bg-profile.jpeg";
// apollo
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { UserDbContent } from "context/UserDbContext";
import PageContentForm from "layouts/pages/components/PageContentForm";
import TabPanel from "layouts/pages/components/TabPanel";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { GQL_PAGES_GET_VARIABLE } from "settings/dynamo-db-helper";
import { getDynamoPagedItems } from "settings/dynamo-db-helper";
import { GQL_PAGE_CONTENT_GET_VARIABLE } from "settings/dynamo-db-helper";
import { Alert, Slide, Snackbar } from "@mui/material";
import { GetDbInfo } from "../../../helpers/AuthHelper";
import CustomLoader from "../components/CustomLoader";


const ADD_QUERY = gql`
mutation($request: DynamoObjectInput!){
  createObject(request: $request){
    success
  }
}`

const GET_QUERY = gql`query($request: DynamoObjectListRequest!){
  objectList(request: $request){
    page
    pageSize
    totalCount
    items{
      objectId
      tableId
      name
      properties{
        propertyId
        name
        value
      }
    }
  }
}`

const UPDATE_QUERY = gql`mutation($request: DynamoCellBulkUpdateInput!){
  updateBulkCellValue(request: $request){
    success
    message
    data{
      dynamoCells{
        id
        value
      }
    }
  }
}`

function DynamicPage(props) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [formData, setFormData] = useState([]);
  const [inputTitle, setInputTitle] = useState("");
  const [inputHeader, setInputHeader] = useState("");
  const [lastLength, setLastLength] = useState(0);
  const [tabValue, setTabValue] = useState(0)
  const [pageContents, setPageContents] = useState([]);
  const [hasPagesData, setHasPagesData] = useState(false);
  const [open, setOpen] = useState(false);

  const { database, tables } = useContext(UserDbContent)

  function tabController(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const { data: pagesData, loading: pagesLoading, error: pagesError, refetch } = useQuery(GET_QUERY, {
    variables: {
      request: {
        tableName: "pages",
        filters: [
          {
            name: "pageName",
            value: props.menuName,
            type: "STRING",
            operator: "LIKE",
          },
        ],
        databaseId: GetDbInfo().dbId,
        properties: GQL_PAGES_GET_VARIABLE
      },
      fetchPolicy: "network-only",
    },
    onCompleted: (d) => {
      if (d) {
        let items = getDynamoPagedItems(d)
        let obj = items[items.length - 1]
        if (obj) {
          setHasPagesData(true);
          setInputTitle(obj.Title);
          setInputHeader(obj.HeaderImageText);
        }
        else {
          setHasPagesData(false)
          setInputTitle("");
          setInputHeader("");
        }
      }
    },
    onError: (e) => {
      alert("error");
    },
  });

  const [getPageContentData,{ data: pageContentData, loading: isContentLoading, error: contentError, refetch: contentRefetch }] = useLazyQuery(GET_QUERY, {
    variables: {
      request: {
        tableName: "pageContent",
        pageSize: 1000,
        filters: [
          {
            name: "pageName",
            value: props.menuName,
            type: "STRING",
            operator: "LIKE",
          },
        ],
        databaseId: GetDbInfo().dbId,
        properties: GQL_PAGE_CONTENT_GET_VARIABLE
      },
    },
    onCompleted: (d) => {
      if (d) {
        let items = getDynamoPagedItems(d);
        let st = items.sort((a, b) => parseInt(a.ContentSerial) - parseInt(b.ContentSerial));

        const newData = st.map((e) => {
          return {
            gridSize: e.GridSize,
            gridContainerProps: e.GridContainerProps,
            contentSerial: e.ContentSerial,
            title: e.Title,
            headerType: e.HeaderType,
            subtitle: e.SubTitle,
            description: e.Description,
            imageurl: e.ImageUrl,
            contentType: e.ContentType,
            objectId: e.objectId
          }
        })
        const previousData = st.map((e) => {
          return {
            gridSize: e.GridSize,
            gridContainerProps: e.GridContainerProps,
            contentSerial: e.ContentSerial,
            title: e.Title,
            headerType: e.HeaderType,
            subtitle: e.SubTitle,
            description: e.Description,
            imageurl: e.ImageUrl,
            contentType: e.ContentType,
            objectId: e.objectId
          }
        })
        setFormData(newData);
        setPageContents(previousData);
      }
    },
    onError: (e) => {
      alert("error");
    },
  });

  

  const [addFormData, { loading: posting, error: postError, data: postData }] = useMutation(ADD_QUERY, {
    onCompleted: (data) => {
      let result = data && data.createObject && data.createObject.success;
      if (result) {
        // alert("Successfully added!");
        setOpen(true);
        contentRefetch()
        refetch()
      } else {
        alert("Failed");
      }
    },
    onError: (e) => {
      alert(e.message);
    },
  });

  const [updateFormData, { loading: updating, error: updateError }] = useMutation(UPDATE_QUERY, {
    onCompleted: (data) => {
      let result = data && data.updateBulkCellValue && data.updateBulkCellValue.success;
      if (result) {
        // alert("Successfully updated!");
        setOpen(true);
        contentRefetch();
      } else {
        alert("Update Failed");
      }
    },
    onError: (e) => {
      alert(e.message);
    },
  });

  useEffect(() => {
    if (props.menuName) {
      setTabValue(0);
      refetch();
    }
  }, [props.menuName])

  useEffect((e) => {
    if(tabValue === 1)
    {
        getPageContentData();
    }
  },[tabValue])


  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleContentChange = (event, index) => {
    const { name, value } = event.target;
    const rowsInput = [...formData];
    rowsInput[index][name].value = value;
    setFormData(rowsInput);
  };

  const handleContentFileChange = (event, value, index) => {
    const { name } = event.target;
    const rowsInput = [...formData];
    rowsInput[index][name].value = value;
    setFormData(rowsInput);
};
  const handleImageRemove = (index) => {
    const name = "imageurl";
    const rowsInput = [...formData];
    rowsInput[index][name].value = "";
    setFormData(rowsInput);
};

  const addContentRows = () => {

    if (formData.length > lastLength) {
      setLastLength(formData.length)
    }
    else {
      setLastLength(lastLength + 1)
    }
    const rowsInput = {
      id: lastLength + 1,
      gridSize: { propertyId: "", value: "" },
      gridContainerProps: { propertyId: "", value: "" },
      contentSerial: { propertyId: "", value: "" },
      title: { propertyId: "", value: "" },
      headerType: { propertyId: "", value: "" },
      subtitle: { propertyId: "", value: "" },
      description: { propertyId: "", value: "" },
      imageurl: { propertyId: "", value: "" },
      contentType: { propertyId: "", value: "" }
    }
    setFormData([...formData, rowsInput])
  }

  const deleteContentRows = (id) => {
    setFormData([]);
    const rows = [...formData];
    const rest = rows.filter(e => e.id !== id);
    const data = [...rest]
    setFormData(data);
  };

  const saveHeader = () => {
    const pagesTable = tables.find(e => e.name == "pages")
    const pagesColumn = pagesTable.columns;

    addFormData({
      variables: {
        request: {
          tableId: pagesTable.id,
          properties: [
            {
              propertyId: pagesColumn.find(p => p.name === "HeaderImageText").id,
              value: inputHeader.value
            },
            {
              propertyId: pagesColumn.find(p => p.name === "PageName").id,
              value: props.menuName
            },
            {
              propertyId: pagesColumn.find(p => p.name === "Title").id,
              value: inputTitle.value
            }
          ]
        }
      },
    });
  }

  const updateContent = () => {
    const contentTable = tables.find(e => e.name == "pageContent")
    const contentColumn = contentTable.columns;

    let updatedData = []
    let addData = []
    let flag = false;
    let flag2 = false;
    for (let i = 0; i < formData.length; i++) {
      let newObject = formData[i]
      // let oldObject = JSON.parse(JSON.stringify(pageContents[i]))

      let keys = Object.keys(newObject)
      if (!keys.includes("objectId")) {
        flag = true;
        addData.push(
          {
            propertyId: contentColumn.find(p => p.name === "GridSize").id,
            value: newObject.gridSize.value
          },
          {
            propertyId: contentColumn.find(p => p.name === "GridContainerProps").id,
            value: newObject.gridContainerProps.value
          },
          {
            propertyId: contentColumn.find(p => p.name === "ContentSerial").id,
            value: newObject.contentSerial.value
          },
          {
            propertyId: contentColumn.find(p => p.name === "Title").id,
            value: newObject.title.value
          },
          {
            propertyId: contentColumn.find(p => p.name === "HeaderType").id,
            value: newObject.headerType.value
          },
          {
            propertyId: contentColumn.find(p => p.name === "SubTitle").id,
            value: newObject.subtitle.value
          },
          {
            propertyId: contentColumn.find(p => p.name === "Description").id,
            value: newObject.description.value
          },
          {
            propertyId: contentColumn.find(p => p.name === "ImageUrl").id,
            value: newObject.imageurl.value
          },
          {
            propertyId: contentColumn.find(p => p.name === "ContentType").id,
            value: newObject.contentType.value
          },
          {
            propertyId: contentColumn.find(p => p.name === "PageName").id,
            value: props.menuName
          },
          {
            propertyId: contentColumn.find(p => p.name === "ContainerStyles").id,
            value: "Menual Container Styles" + (i + 1)
          },
          {
            propertyId: contentColumn.find(p => p.name === "ContentStyles").id,
            value: "Content Styles" + (i + 1)
          }
        );
      }

      else {
        flag2 = true;
        for (let j = 0; j < keys.length; j++) {
          let prp = keys[j]
          // let isChanged = newObject[prp].value !== oldObject[prp].value

          // if(isChanged){
          //   updatedData.push({
          //     columnId: newObject[prp].propertyId,
          //     value: newObject[prp].value
          //   })
          // }

          if (keys[j] !== 'objectId') {
            updatedData.push({
              columnId: newObject[prp].propertyId,
              value: newObject[prp].value
            })
          }

        }
      }
    }

    if (flag === true) {
      addFormData({
        variables: {
          request: {
            tableId: contentTable.id,
            properties: addData
          }
        }
      })
    }
    if (flag2 === true) {
      updateFormData({
        variables: {
          request: {
            dynamoCells: updatedData
          }
        }
      })
    }

  }

  const updateHeader = () => {
    updateFormData({
      variables: {
        request: {
          dynamoCells: [
            {
              columnId: inputHeader.propertyId,
              value: inputHeader.value
            },
            {
              columnId: inputTitle.propertyId,
              value: inputTitle.value
            }
          ]
        }
      }
    })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox position="relative" mb={5}>

        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <MDBox>
            <MDBox lineHeight={0} sx={{ mb: 5, textAlign: "center" }}>
              <MDTypography variant="h5">{props.menuName}</MDTypography>
              <MDTypography variant="button" color="text">
                Mandatory informations
              </MDTypography>
            </MDBox>
          </MDBox>

          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Header" {...tabController(0)} />
                <Tab label="Content" {...tabController(1)} />
              </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
              {
                pagesLoading ?
                  <CustomLoader />
                  :
                  <Grid container spacing={3} mt={4}>
                    <Grid item md={6}>
                      <MDInput fullWidth value={inputHeader.value} label="Header Text" onChange={(e) => setInputHeader({ propertyId: inputHeader.propertyId, value: e.target.value })} />
                    </Grid>
                    <Grid item md={6}>
                      <MDInput fullWidth value={inputTitle.value} label="Header Title" onChange={(e) => setInputTitle({ propertyId: inputTitle.propertyId, value: e.target.value })} />
                    </Grid>
                  </Grid>
              }

              <Grid container>
                <Grid item xs={6}>
                  {
                    hasPagesData ?

                      (posting || updating ?
                        <MDButton
                          variant="gradient"
                          color="secondary"
                          sx={{ float: 'right', width: '20%', mr: -7, mt: 7 }}
                        >
                          Updating
                        </MDButton> :
                        <MDButton
                          variant="gradient"
                          color="secondary"
                          sx={{ float: 'right', width: '20%', mr: -7, mt: 7 }}
                          onClick={updateHeader}
                        >
                          Update
                        </MDButton>)
                      :
                      (posting || updating ?
                        <MDButton
                          variant="gradient"
                          color="secondary"
                          sx={{ float: 'right', width: '20%', mr: -7, mt: 7 }}
                        >
                          Saving
                        </MDButton> :
                        <MDButton
                          variant="gradient"
                          color="secondary"
                          sx={{ float: 'right', width: '20%', mr: -7, mt: 7 }}
                          onClick={saveHeader}
                        >
                          Save
                        </MDButton>)

                  }
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              {
                isContentLoading ?
                  <CustomLoader />
                  :
                  <Grid container spacing={5} >
                    {
                      formData.map((e, i) => {
                        return (
                          <Fragment key={e.id}>
                            <Grid item xs={12} sm={12} md={6}>
                              <MDButton
                                variant="gradient"
                                color="primary"
                                onClick={() => deleteContentRows(e.id)}
                                sx={{ float: 'right', mt: 2 }}
                              >
                                <h3>X</h3>
                              </MDButton>
                              <PageContentForm contentValue={e} onChangeEvent={handleContentChange}  onFileChange={handleContentFileChange} handleImageRemove={handleImageRemove} hasImage={e.imageurl.value} index={i} />
                              {
                                e.imageurl.value !== "" &&
                                <img src={e.imageurl.value} alt="Invalid image link" height="150px" width="250px" />
                              }
                            </Grid>
                          </Fragment>
                        )
                      })
                    }
                  </Grid>
              }

              <Grid container direction="row" spacing={3}>
                <Grid item xs={6} >
                  <MDButton
                    variant="gradient"
                    color="info"
                    sx={{ float: "right", mt:3 }}
                    onClick={addContentRows}
                  >
                    Add Content
                  </MDButton>{" "}
                </Grid>

                <Grid item xs={6}>
                  {
                    posting || updating ?
                      <MDButton
                        variant="gradient"
                        color="secondary"
                        sx={{ width: '20%', mt: 3 }}
                      >
                        Saving
                      </MDButton> :
                      <MDButton
                        variant="gradient"
                        color="secondary"
                        sx={{ width: '20%', mt: 3 }}
                        onClick={updateContent}
                      >
                        Save
                      </MDButton>
                  }
                </Grid>
              </Grid>
            </TabPanel>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                Successfully Added !
              </Alert>
            </Snackbar>
          </Box>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

// Setting default props for the Header
DynamicPage.defaultProps = {
  children: "",
};

// Typechecking props for the Header
DynamicPage.propTypes = {
  children: PropTypes.node,
};

export default DynamicPage;
