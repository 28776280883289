/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useState, useContext, useEffect} from "react";

// react-router-dom components
import { Routes, Route, Navigate, useLocation, useNavigate, Link  } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import AuthLayout from "./AuthLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { AuthContext } from "../../../context/AuthContext";

const users = [
    {userName: "admin@vehiclesolution.net", password:"Admin@123", databaseName: "www.vehiclesolution.net"},
    {userName: "admin@dynamic.xeontechnology.ltd", password:"Admin@123", databaseName: "dynamic.xeontechnology.ltd"},
    {userName: "admin@eurocoach.com.bd", password:"Admin@123", databaseName: "www.eurocoach.com.bd"},
    {userName: "admin@rayartrn.com", password:"Admin@123", databaseName: "www.rayartrn.com"},
]

function Basic() {
    const [rememberMe, setRememberMe] = useState(false);
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")

    const { isAuthenticated, setAuthValue, authenticate } = useContext(AuthContext)
    const handleSetRememberMe = () => setRememberMe(!rememberMe);
    let navigate = useNavigate();

    function handleSignIn(){
        authenticate({username: userName, password})
    }

    if(isAuthenticated){
        navigate("/dashboards/analytics")
    }

    return (
        <AuthLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Sign in
                    </MDTypography>
                    <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                        <Grid item xs={2}>
                            <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                                <FacebookIcon color="inherit" />
                            </MDTypography>
                        </Grid>
                        <Grid item xs={2}>
                            <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                                <GitHubIcon color="inherit" />
                            </MDTypography>
                        </Grid>
                        <Grid item xs={2}>
                            <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                                <GoogleIcon color="inherit" />
                            </MDTypography>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <MDBox mb={2}>
                            <MDInput type="email" onChange={(e) => setUserName(e.target.value)} label="Email" fullWidth />
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput type="password" onChange={(e) => setPassword(e.target.value)} label="Password" fullWidth />
                        </MDBox>
                        {/*<MDBox display="flex" alignItems="center" ml={-1}>*/}
                        {/*    <Switch checked={rememberMe} onChange={handleSetRememberMe} />*/}
                        {/*    <MDTypography*/}
                        {/*        variant="button"*/}
                        {/*        fontWeight="regular"*/}
                        {/*        color="text"*/}
                        {/*        onClick={handleSetRememberMe}*/}
                        {/*        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}*/}
                        {/*    >*/}
                        {/*        &nbsp;&nbsp;Remember me*/}
                        {/*    </MDTypography>*/}
                        {/*</MDBox>*/}
                        <MDBox mt={4} mb={1}>
                            <MDButton variant="gradient" onClick={handleSignIn} color="info" fullWidth>
                                sign in
                            </MDButton>
                        </MDBox>
                        {/*<MDBox mt={3} mb={1} textAlign="center">*/}
                        {/*    <MDTypography variant="button" color="text">*/}
                        {/*        Don&apos;t have an account?{" "}*/}
                        {/*        <MDTypography*/}
                        {/*            component={Link}*/}
                        {/*            to="/authentication/sign-up/cover"*/}
                        {/*            variant="button"*/}
                        {/*            color="info"*/}
                        {/*            fontWeight="medium"*/}
                        {/*            textGradient*/}
                        {/*        >*/}
                        {/*            Sign up*/}
                        {/*        </MDTypography>*/}
                        {/*    </MDTypography>*/}
                        {/*</MDBox>*/}
                    </MDBox>
                </MDBox>
            </Card>
        </AuthLayout>
    );
}

export default Basic;