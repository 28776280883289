import MDAvatar from "components/MDAvatar";
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";
import Logout from "layouts/pages/auth/Logout";
import Icon from "@mui/material/Icon";
import profilePicture from "assets/images/team-4.jpg";
import DynamicPage from "layouts/pages/dynamicPage/DynamicPage";
import NavigationPage from "layouts/pages/navigation/NavigationPage";
import HomePage from "layouts/pages/home/HomePage";
import ContactUs from "layouts/pages/contact-us/ContactUsPage";
import GalleryPage from "layouts/pages/gallery/GalleryPage";
import Footer from "layouts/pages/footer/FooterPage";
import ConfigurationPage from "layouts/pages/confogurationPage/ConfigurationPage";
import { AuthContext } from "context/AuthContext";
import { useContext } from "react";

export function getCellValue(properties, name){
    let value = ""
    let cell = properties.find(a => a.name === name)
    if(cell){
        value = cell.value
    }
    return value;
}

export function getSimplifiedObject(dynamoDbObj){
    let resultObj = {}
    if(dynamoDbObj){
        for(let i = 0; i < dynamoDbObj.properties.length; i++){
            let p = dynamoDbObj.properties[i]
            resultObj = {...resultObj, [p.name]: p.value}
        }
    }
    return resultObj
}

export function getSimplifiedObjectWithProperty(dynamoDbObj){
  let resultObj = {}
  if(dynamoDbObj){
      for(let i = 0; i < dynamoDbObj.properties.length; i++){
          let p = dynamoDbObj.properties[i]
          resultObj = {...resultObj, [p.name]: {'value': p.value, 'propertyId': p.propertyId}}
      }
  }
  return resultObj
}
export function getSimplifiedRouteObject(dynamoDbObj){
    let resultObj = {}
    if(dynamoDbObj){
        for(let i = 0; i < dynamoDbObj.length; i++){
            let p = dynamoDbObj[i]
            resultObj = {...resultObj, [p.name]: p.value}
        }
    }
    return resultObj
}

export function getDynamoPagedItems(res){
  let items = []
  if(res){
    let dItems = res.objectList && res.objectList.items && res.objectList.items ? res.objectList && res.objectList.items && res.objectList.items: []
    for(let i = 0; i < dItems.length; i ++){
      //let simpleItem = getSimplifiedObject(dItems[i])
      let simpleItem = getSimplifiedObjectWithProperty(dItems[i])
      simpleItem.objectId = dItems[i].objectId
      items.push(simpleItem)
    }
  }
  return items
}
export function getDynamoRoutesItems(res){
  let items = []
  if(res){
    let dItems = res.objectList && res.objectList.items && res.objectList.items ? res.objectList && res.objectList.items && res.objectList.items: []
    let simplifiedData = dItems.map(e => e.properties)
    for(let i = 0; i < simplifiedData.length; i ++){
      let simpleItem = getSimplifiedRouteObject(simplifiedData[i])
      items.push(simpleItem)
    }
  }
  return items
}

export const GQL_GET_ROUTES = [
    {
      "name": "MenuName"
    },
    {
      "name": "Parent"
    },
    {
      "name": "RoutePath"
    },
    {
      "name": "SliderTextLink"
    },
    {
      "name": "NavSerial"
    },
    {
      "name": "ComponentName"
    }
  ];

  export const GQL_PAGES_GET_VARIABLE = [
     {
      name: "Title",
     },
     {
      name: "HeaderImageText",
     },
     {
      name: "HeaderImageUrl",
     },
     {
      name: "HeaderImageUrl",
     },
     {
      name: "PageName",
     },
    ];
    
    export const GQL_PAGE_CONTENT_GET_VARIABLE = [
     {
      name: "PageName",
     },
     {
      name: "HeaderText",
     },
     {
      name: "HeaderType",
     },
     {
      name: "Title",
     },
     {
      name: "SubTitle",
     },
     {
      name: "Description",
     },
     {
      name: "ImageUrl",
     },
     {
      name: "GridSize",
     },
     {
      name: "GridContainerProps",
     },
     {
      name: "ContentSerial",
     },
     {
      name: "ContainerStyles",
     },
     {
       name: "ContentStyles",
     },
     {
       name: "ContentType",
     }
    ];


  const selectComponent = (e, mName) =>{
    switch (e) {
      case "home":
        return <HomePage />
      case "gallery":
        return <GalleryPage />
      default:
        return <DynamicPage menuName={mName} />
    }
  }

  export const getRoutesFormat = (element) => {
    let data=[]

    //const {getUserInfo} = useContext(AuthContext);

    element.map((e, i) => {
        data.push({
                name: e.MenuName,
                key: e.MenuName,
                route: e.RoutePath,
                // component: e.ComponentName
                component: selectComponent(e.RoutePath, e.MenuName)
              })
        })

    let route=[];
    route=[
      {
        type: "collapse",
        name: "Admin",
        key: "admin",
        icon: <MDAvatar src={profilePicture} alt="No Image" size="sm" />,
        collapse: [
          {
            name: "My Profile",
            key: "my-profile",
            route: "/pages/profile/profile-overview",
            component: <ProfileOverview />,
          },
          {
            name: "Settings",
            key: "profile-settings",
            route: "/pages/account/settings",
            component: <Settings />,
          },
          {
            name: "Logout",
            key: "logout",
            route: "/logout",
            component: <Logout />,
          },
        ],
      },
      { type: "divider", key: "divider-0" },
      {
        type: "collapse",
        name: "Dashboards",
        key: "dashboards",
        icon: <Icon fontSize="medium">dashboard</Icon>,
        collapse: [
          {
            name: "Analytics",
            key: "analytics",
            route: "/dashboards/analytics",
            component: <Analytics />,
          },
          {
            name: "Sales",
            key: "sales",
            route: "/dashboards/sales",
            component: <Sales />,
          },
        ],
      },
      { type: "divider", key: "divider-1" },
      {
        type: "collapse",
        name: "Navigation",
        key: "navigation",
        icon: <Icon fontSize="medium">add</Icon>,
        collapse: [
          {
            name: "Create Page",
            key: "createPage",
            route: "/dashboards/create-new-page",
            component: <NavigationPage />,
          },
          {
            name: "Configuration",
            key: "configuration",
            route: "/configuration",
            component: <ConfigurationPage />,
          },
          // {
          //   name: "Footer",
          //   key: "footer",
          //   route: "/footer",
          //   component: <Footer />,
          // }
        ],
      },
      { type: "title", title: "Pages", key: "title-pages" },
      {
        type: "collapse",
        name: "Pages",
        key: "pages",
        icon: <Icon fontSize="medium">image</Icon>,
        collapse: data
      },    
    ];
    return route;
  } 
