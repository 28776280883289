import React, { useState } from 'react'
import MDInput from "components/MDInput";
import { Grid } from '@mui/material';
import MDButton from 'components/MDButton';
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from '@mui/icons-material/Delete';
import UploadImageModal from './UploadImageModal';

function ConfigurationForm(props) {
    const [open, setOpen] = useState(false);

    const handleOpenModal = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    return (
        <>
            <Grid container>
                <Grid item md={12} sm={12}>
                    <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ width: "25%", mb: 3 }}
                        onClick={handleOpenModal}
                    >
                        Upload Logo <UploadIcon sx={{ marginLeft: "15%" }} />
                    </MDButton>
                    {
                        props.hasImage !=="" &&
                        <MDButton
                            variant="gradient"
                            color="error"
                            sx={{ width: "20%", mb: 3, ml:2}}
                            onClick={() => props.handleImageRemove(props.index)}
                        >
                            Remove <DeleteIcon sx={{ marginLeft: "15%" }} />
                        </MDButton>
                    }
                    {/* <MDInput name="logoUrl" value={(props.contentValue).logoUrl.value} label="Logo Url" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} /> */}
                </Grid>
            </Grid>

            <Grid container>
                <Grid item md={12} sm={12}>
                    <MDInput name="contactEmail" value={(props.contentValue).contactEmail.value} label="Contact Email" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item md={12} sm={12}>
                    <MDInput name="contactNumber" value={(props.contentValue).contactNumber.value} label="Contact Number" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} />
                </Grid>
            </Grid>
            
            <Grid container>
                <Grid item md={12} sm={12}>
                    <MDInput name="template" value={(props.contentValue).template.value} label="Template" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} />
                </Grid>
            </Grid>
            
            {/* <Grid container>
                <Grid item md={12} sm={12}>
                    <MDInput name="googleApiKey" value={(props.contentValue).googleApiKey.value}  label="Google Api Key" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} />
                </Grid>
            </Grid> */}

            <Grid direction="row" spacing={3} container>
                <Grid item md={6} sm={12}>
                    <MDInput name="gpsLNG" value={(props.contentValue).gpsLNG.value}  label="Gps Longitude" sx={{ mb: 2 }} fullWidth onChange={(e) => props.onChangeEvent(e, props.index)} />
                </Grid>
                
                <Grid item md={6} sm={12}>
                    <MDInput name="gpsLAT" value={(props.contentValue).gpsLAT.value}  label="Gps Latitude" sx={{ mb: 2 }} fullWidth onChange={(e) => props.onChangeEvent(e, props.index)} />
                </Grid>
            </Grid>
            <UploadImageModal
                open={open}
                setOpen = {setOpen}
                handleClose={handleClose}
                onFileChange = {props.onFileChange}
                selectedFor={props.index}
            />
        </>
    );
}

export default ConfigurationForm;