let tokenKey = "token";
let dbNameKey = "dbName"
let dbIdKey = "dbId"

export function GetAuthToken(){
    let token = localStorage.getItem(tokenKey)
    return token;
}

export function SetAuthToken(token){
    localStorage.setItem(tokenKey, token)
    return true
}

export function RemoveAuthToken(){
    localStorage.removeItem(tokenKey)
    localStorage.removeItem(dbNameKey)
    localStorage.removeItem(dbIdKey)
}

export function SetDbInfo(dbName, dbId){
    localStorage.setItem(dbNameKey, dbName)
    localStorage.setItem(dbIdKey, dbId)
    return true
}

export function GetDbInfo(){
    let result = {
        dbId: "",
        dbName: ""
    }
    if(localStorage.getItem(dbIdKey)){
        result.dbId = localStorage.getItem(dbIdKey)
    }
    if(localStorage.getItem(dbNameKey)){
        result.dbName = localStorage.getItem(dbNameKey)
    }
    return result;
}
