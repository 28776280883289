import { useState, useEffect, useContext, Fragment } from "react";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React Components
import MDButton from "components/MDButton";
import breakpoints from "assets/theme/base/breakpoints";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Images
import backgroundImage from "assets/images/bg-profile.jpeg";
import { UserDbContent } from "context/UserDbContext";
import ConfigurationForm from "../components/ConfigurationForm";
import { GQL_GET_CONFIG_DATA } from "settings/gqlVariables";
import { GetDbInfo } from "helpers/AuthHelper";
import { getDynamoPagedItems } from "settings/dynamo-db-helper";
import { Alert,  Snackbar } from "@mui/material";
import CustomLoader from "../components/CustomLoader";

const ADD_QUERY = gql`
mutation($request: DynamoObjectInput!){
  createObject(request: $request){
    success
  }
}`

const GET_QUERY = gql`query($request: DynamoObjectListRequest!){
  objectList(request: $request){
    page
    pageSize
    totalCount
    items{
      objectId
      tableId
      name
      properties{
        propertyId
        name
        value
      }
    }
  }
}`

const UPDATE_QUERY = gql`mutation($request: DynamoCellBulkUpdateInput!){
  updateBulkCellValue(request: $request){
    success
    message
    data{
      dynamoCells{
        id
        value
      }
    }
  }
}`

function ConfigurationContent() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  let rowsInput = {
    imageurl: { propertyId: "", value: "" },
    contactEmail: { propertyId: "", value: "" },
    contactNumber: { propertyId: "", value: "" },
    template: { propertyId: "", value: "" },
    gpsLNG: { propertyId: "", value: "" },
    gpsLAT: { propertyId: "", value: "" }
  }
  const [formData, setFormData] = useState([rowsInput]);

  const [isUpdateData, setIsUpdateData] = useState(false);
  const [open, setOpen] = useState(false);


  const { database, tables } = useContext(UserDbContent)

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const rowsInput = [...formData];
    rowsInput[index][name].value = value;
    setFormData(rowsInput);
  };

  const handleContentFileChange = (event, value, index) => {
    const { name } = event.target;
    const rowsInput = [...formData];
    rowsInput[index][name].value = value;
    setFormData(rowsInput);
};

const handleImageRemove = (index) => {
  const name = "imageurl";
  const rowsInput = [...formData];
  rowsInput[index][name].value = "";
  setFormData(rowsInput);
};

  const handleSplitMapData = (a) => {
    let result = a.split('|');
    return result;
  }

  const [addFormData,
    { loading: posting, error: postError, data: postData },
  ] = useMutation(ADD_QUERY, {
    onCompleted: (data) => {
      let result = data && data.createObject && data.createObject.success;
      if (result) {
        alert("Successfully added!");
      } else {
        alert("Failed");
      }
    },
    onError: (e) => {
      alert(e.message);
    },
  });

  const { data, loading, error, refetch } = useQuery(GET_QUERY, {
    variables: {
      request: {
        tableName: "siteConfigs",
        databaseId: GetDbInfo().dbId,
        properties: GQL_GET_CONFIG_DATA
      },
      fetchPolicy: "network-only",
    },
    onCompleted: (d) => {
      if (d.objectList.items.length !== 0) {
        let items = getDynamoPagedItems(d)
        const newData = items.map((e) => {
          let mapData = handleSplitMapData(e.GPSCoordinates.value)
          return {
            imageurl: e.LogoUrl,
            contactEmail: e.ContactEmail,
            contactNumber: e.ContactNumber,
            template: e.Template,
            gpsLNG: { propertyId: e.GPSCoordinates.propertyId, value: mapData[0] },
            gpsLAT: { propertyId: "", value: mapData[1] }
          }
        })
        setFormData(newData);
        setIsUpdateData(true);
      }
      else {
        console.log("No previous data available")
      }
    },
    onError: (e) => {
      alert("error");
    },
  });

  const [updateFormData, { loading: updating, error: updateError }] = useMutation(UPDATE_QUERY, {
    onCompleted: (data) => {
      let result = data && data.updateBulkCellValue && data.updateBulkCellValue.success;
      if (result) {
        // alert("Successfully updated!");
        setOpen(true);
      } else {
        alert("Update Failed");
      }
    },
    onError: (e) => {
      alert(e.message);
    },
  });

  const handleJoinMapData = (a, b) => {
    let result = [a, b].join('|');
    return result;
  }

  const saveData = () => {
    const routesTable = tables.find(e => e.name == "siteConfigs")
    const routesColumn = routesTable.columns;
    if (isUpdateData === false) {
      formData.map((e, i) => {
        let apiKey = handleJoinMapData(e.gpsLNG.value, e.gpsLAT.value)
        addFormData({
          variables: {
            request: {
              tableId: routesTable.id,
              properties: [
                {
                  propertyId: routesColumn.find(p => p.name === "ContactEmail").id,
                  value: e.contactEmail.value
                },
                {
                  propertyId: routesColumn.find(p => p.name === "ContactNumber").id,
                  value: e.contactNumber.value
                },
                {
                  propertyId: routesColumn.find(p => p.name === "LogoUrl").id,
                  value: e.imageurl.value
                },
                {
                  propertyId: routesColumn.find(p => p.name === "Template").id,
                  value: e.template.value
                },
                {
                  propertyId: routesColumn.find(p => p.name === "GPSCoordinates").id,
                  value: apiKey
                }
              ]
            }
          },
        });
      })
    }
    else if (isUpdateData === true) {
      let updatedData = [];
      formData.map(e => {
        let apiKey = handleJoinMapData(e.gpsLNG.value, e.gpsLAT.value)
        updatedData.push(
          {
            columnId: e.contactEmail.propertyId,
            value: e.contactEmail.value
          },
          {
            columnId: e.contactNumber.propertyId,
            value: e.contactNumber.value
          },
          {
            columnId: e.imageurl.propertyId,
            value: e.imageurl.value
          },
          {
            columnId: e.template.propertyId,
            value: e.template.value
          },
          {
            columnId: e.gpsLNG.propertyId,
            value: apiKey
          }
        )
      })
      if (updatedData !== []) {
        updateFormData({
          variables: {
            request: {
              dynamoCells: updatedData
            }
          }
        })
      }
    }
  }

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);
    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);




  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <MDBox>
          <MDBox lineHeight={0} sx={{ mb: 5, textAlign: "center" }}>
            <MDTypography variant="h5">Configuration</MDTypography>
            <MDTypography variant="button" color="text">
              Mandatory informations
            </MDTypography>
          </MDBox>
        </MDBox>

        {
          loading ?
            <CustomLoader /> :
            <Grid container direction="column" alignItems="center" >
              {
                formData.map((e, i) => {
                  return (
                    <Fragment key={i}>
                      <Grid item xs={12} sm={12} md={12} sx={{ width: "50%" }}>
                        {
                            e.imageurl.value !== "" &&
                            <img src={e.imageurl.value} alt="Invalid image link" height="150px" width="250px" />
                        }
                        <ConfigurationForm contentValue={e} onChangeEvent={handleChange} onFileChange={handleContentFileChange} index={i}  handleImageRemove={handleImageRemove} hasImage={e.imageurl.value} />
                      </Grid>
                    </Fragment>
                  )
                })
              }
              <Grid item md={6}>
                {
                  updating ?
                    <MDButton
                      variant="gradient"
                      color="secondary"
                      sx={{ float: 'right', width: '20%', mr: -7, mt: 3 }}
                    >
                      Updating
                    </MDButton> :
                    <MDButton
                      variant="gradient"
                      color="secondary"
                      sx={{ float: 'right', width: '20%', mr: -7, mt: 3 }}
                      onClick={saveData}
                    >
                      Save
                    </MDButton>
                }
              </Grid>
            </Grid>
        }

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Successfully Added !
          </Alert>
        </Snackbar>
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
ConfigurationContent.defaultProps = {
  children: "",
};

// Typechecking props for the Header
ConfigurationContent.propTypes = {
  children: PropTypes.node,
};

export default ConfigurationContent;
