export const GQL_GET_CONFIG_DATA = [
  {
    name: "LogoUrl",
  },
  {
    name: "ContactEmail",
  },
  {
    name: "ContactNumber",
  },
  {
    name: "Template",
  },
  {
    name: "GPSCoordinates",
  },
];
