import { useState, useEffect, useContext, Fragment } from "react";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React Components
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
// Images
import backgroundImage from "assets/images/bg-profile.jpeg";
// apollo
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { UserDbContent } from "context/UserDbContext";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { DB_ID } from "settings/Configurations";
import { getCellValue } from "settings/dynamo-db-helper";
import { GetDbInfo } from "../../../../helpers/AuthHelper";
import { GQL_GET_ROUTES } from "settings/dynamo-db-helper";
import { getDynamoPagedItems } from "settings/dynamo-db-helper";
import { Alert, Snackbar } from "@mui/material";

const ADD_QUERY = gql`
mutation($request: DynamoObjectInput!){
  createObject(request: $request){
    success
  }
}`

const GET_QUERY = gql`
query($request: DynamoObjectListRequest!){
  objectList(request: $request){
    page
    pageSize
    totalCount
    items{
      objectId
      tableId
      name
      properties{
        name
        value
      }
    }
  }
}
`

function FooterPageContent() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [formData, setFormData] = useState([]);
  const [numberOfGrid, setNumberOfGrid] = useState("1");
  const [lastLength, setLastLength] = useState(0);
  const [pageObj, setPageObj] = useState();
  const [pageContents, setPageContents] = useState([]);
  const [previousData, setPreviousData] = useState();
  const [open, setOpen] = useState(false);


  const { database, tables } = useContext(UserDbContent)

  useEffect(() => {
    getPageObject()
  }, [])

  const [getPageObject] = useLazyQuery(GET_QUERY, {
    variables: {
      "request": {
        "tableName": "footer",
        "databaseId": GetDbInfo().dbId,
        "properties": [
          {
            "name": "PageName"
          },
          {
            "name": "footerStyles"
          },
          {
            "name": "NumberOfGrid"
          }
        ]
      }
    },
    onCompleted: (d) => {
      if (d) {
        let obj = d.objectList && d.objectList.items && d.objectList.items[d.objectList.items.length - 1]
        if (obj) {
          let data = obj && obj.properties
          setPageObj(getCellValue(data, "NumberOfGrid"))
          // console.log(getCellValue(data, "NumberOfGrid"));
        }
      }
      getPageContentObject()

    },
    onError: (e) => {
      alert("error")
      setPageObj(null)
    }
  })

  const [getPageContentObject] = useLazyQuery(GET_QUERY, {
    variables: {
      "request": {
        "tableName": "footerContent",
        "databaseId": GetDbInfo().dbId,
        "properties": [
          {
            "name": "ContentType"
          },
          {
            "name": "Content"
          },
          {
            "name": "Link"
          },
          {
            "name": "LinkType"
          },
          {
            "name": "Icon"
          },
          {
            "name": "Grid"
          },
          {
            "name": "ContentSerial"
          },
          {
            "name": "ContentStyles"
          }
        ]
      }
    },
    onCompleted: (d) => {
      if (d) {
        let obj = d.objectList && d.objectList.items && d.objectList.items

        if (obj) {
          let properties = obj.map(e => e.properties);
          setPageContents(properties)

          const rowsInput = {
            totalGrid: pageObj + "",
            grid: getCellValue(properties, "Grid"),
            contentType: getCellValue(properties, "ContentType"),
            linkType: getCellValue(properties, "LinkType"),
            internelLink: getCellValue(properties, "Link"),
            externelLink: getCellValue(properties, "Link"),
            generelText: getCellValue(properties, "Content"),
            icon: getCellValue(properties, "Icon"),
            sequence: getCellValue(properties, "ContentSerial")
          }
          setFormData([...formData, rowsInput])
        }
      }

    },
    onError: (e) => {
      alert("error")
    }
  })

  const [getRoutesInfo] = useLazyQuery(GET_QUERY, {
    variables: {
      request: {
        tableName: "routes",
        databaseId: GetDbInfo().dbId,
        properties: GQL_GET_ROUTES
      },
    },
    onCompleted: (d) => {
      if (d) {
        let items = getDynamoPagedItems(d);
        let st = items.sort((a, b) => parseInt(a.ContentSerial) - parseInt(b.ContentSerial));
        setPreviousData(st);
      }
    },
    onError: (e) => {
      alert("error");
    },
  });

  useEffect(() => {
    getRoutesInfo();
  }, [])

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };



  const [addFormData,
    { loading: posting, error: postError, data: postData },
  ] = useMutation(ADD_QUERY, {
    onCompleted: (data) => {
      let result = data && data.createObject && data.createObject.success;
      if (result) {
        setOpen(true);
      } else {
        alert("Failed");
      }
    },
    onError: (e) => {
      alert(e.message);
    },
  });

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const rowsInput = [...formData];
    rowsInput[index][name] = value;
    setFormData(rowsInput);
  };



  const addRows = (i) => {
    if (formData.length > lastLength) {
      setLastLength(formData.length)
    }
    else {
      setLastLength(lastLength + 1)
    }
    const rowsInput = {
      id: lastLength + 1,
      totalGrid: numberOfGrid,
      grid: i + "",
      contentType: "",
      linkType: "",
      internelLink: "",
      externelLink: "",
      generelText: "",
      icon: "",
      sequence: ""
    }
    setFormData([...formData, rowsInput])
  }

  const deleteRows = (id) => {
    setFormData([]);
    const rows = [...formData];
    const rest = rows.filter(e => e.id !== id);
    const data = [...rest]
    setFormData(data);
  };

  const isValidate = (inputValue) => {
    if (isNaN(inputValue)) {
      return false;
    }
    return true;
  }

  const saveData = () => {
    const contentTable = tables.find(e => e.name == "footerContent")
    const contentColumn = contentTable.columns;
    const footerTable = tables.find(e => e.name == "footer")
    const footerColumn = footerTable.columns;
    formData.map((e, i) => {
      addFormData({
        variables: {
          request: {
            tableId: contentTable.id,
            properties: [
              {
                propertyId: contentColumn.find(p => p.name === "ContentType").id,
                value: e.contentType
              },
              {
                propertyId: contentColumn.find(p => p.name === "Content").id,
                value: e.generelText
              },
              {
                propertyId: contentColumn.find(p => p.name === "Link").id,
                value: e.externelLink ? e.externelLink : e.internelLink
              },
              {
                propertyId: contentColumn.find(p => p.name === "LinkType").id,
                value: e.linkType
              },
              {
                propertyId: contentColumn.find(p => p.name === "Icon").id,
                value: e.icon
              },
              {
                propertyId: contentColumn.find(p => p.name === "Grid").id,
                value: e.grid
              },
              {
                propertyId: contentColumn.find(p => p.name === "ContentSerial").id,
                value: e.sequence + ""
              },
              {
                propertyId: contentColumn.find(p => p.name === "ContentStyles").id,
                value: "style property here"
              }
            ]
          }
        },
      });
    })

    addFormData({
      variables: {
        request: {
          tableId: footerTable.id,
          properties: [
            {
              propertyId: footerColumn.find(p => p.name === "NumberOfGrid").id,
              value: numberOfGrid
            },
            {
              propertyId: footerColumn.find(p => p.name === "PageName").id,
              value: "FOOTER"
            },
            {
              propertyId: footerColumn.find(p => p.name === "footerStyles").id,
              value: "style content"
            }
          ]
        }
      },
    });

  }

  function handleChangeGridSize(e) {
    let value = e.target.value
    if (value === "") {
      value = "1"
    }
    let pre = numberOfGrid.split("-")
    pre[0] = e.target.value

    for (let i = 0; i <= value; i++) {
      //result = result + "-"+ value[i]
      if (!pre[i]) {
        pre[i] = "1"
      }
    }

    let result = pre.join("-")
    setNumberOfGrid(result)
  }

  const handleChangeGridSizePerRow = (e, i) => {
    let pre = numberOfGrid.split("-")
    if (pre[i]) {
      pre[i + 1] = e.target.value
    }

    let result = pre.join("-")
    setNumberOfGrid(result)
  }

  const handleSplit = (i) => {
    let data = pageObj + ""
    let pre = data && data.split("-")
    return pre[i];
  }


  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <MDBox>
          <MDBox lineHeight={0} sx={{ mb: 5, textAlign: "center" }}>
            <MDTypography variant="h5">Footer</MDTypography>
            <MDTypography variant="button" color="text">
              Mandatory informations
            </MDTypography>
          </MDBox>
        </MDBox>

        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <FormControl sx={{ mb: 2, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Total Grid</InputLabel>
              <Select
                sx={{ padding: 1.5 }}
                autoWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="numberOfGrid"
                label="Total Grid"
                onChange={(e) => handleChangeGridSize(e)}
              >
                {[...Array(12)].map((nav, navi) => {
                  return (
                    <MenuItem value={navi + 1} key={navi}>{navi + 1}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container sx={{ my: 3, ml: 1 }}>
          {

            [...Array(parseInt(numberOfGrid.split("-")[0]))].map((g, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => addRows(index + 1)}
                  >
                    +
                  </MDButton>{" "} &nbsp;

                  <FormControl sx={{ mb: 2, minWidth: 150 }}>
                    <InputLabel id="demo-simple-select-label">Grid Size</InputLabel>
                    <Select
                      sx={{ padding: 1.5 }}
                      autoWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="gridSize"
                      label="Grid Size"
                      onChange={(p) => handleChangeGridSizePerRow(p, index)}
                    >
                      {[...Array(12)].map((nav, navi) => {
                        return (
                          <MenuItem value={navi + 1} key={navi}>{navi + 1}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>


                  {
                    formData.map((e, i) => {
                      return (
                        <>
                          {(parseInt(e.grid) === index + 1) &&

                            <Grid container sx={{ ml: 3 }}>

                              <Fragment key={e.id}>
                                {/* <MDButton
                                            variant="gradient"
                                            color="primary"
                                            onClick={() => deleteRows(e.id)}
                                            sx={{float:'right', mt:2}}
                                            >
                                              <h3>X</h3>
                                            </MDButton> */}
                                <Grid item xs={2}>
                                  <FormControl sx={{ mb: 2 }}>
                                    <InputLabel id="demo-simple-select-label">Select Content Type</InputLabel>
                                    <Select
                                      sx={{ padding: 1.5, width: 200 }}
                                      name="contentType"
                                      label="Select Content Type"
                                      value={e.contentType.value || ''}
                                      onChange={(f) => handleChange(f, i)}
                                    >
                                      <MenuItem value="Title" >Title</MenuItem>
                                      <MenuItem value="Subtitle" >Subtitle</MenuItem>
                                      <MenuItem value="Description" >Description</MenuItem>
                                      <MenuItem value="Link" >Link</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <>{(e.contentType === "Link") &&
                                  <Grid item xs={2}>
                                    <FormControl sx={{ mb: 2 }}>
                                      <InputLabel id="demo-simple-select-label">Select Link Type</InputLabel>
                                      <Select
                                        sx={{ padding: 1.5, width: 200 }}
                                        name="linkType"
                                        label="Select Link Type"
                                        defaultValue={e.linkType.value || ''}
                                        onChange={(f) => handleChange(f, i)}
                                      >
                                        <MenuItem value="Internel" >Internel</MenuItem>
                                        <MenuItem value="Externel" >Externel</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                }</>

                                <>{(e.linkType === "Internel" && e.contentType === "Link") &&
                                  <Grid item xs={2}>
                                    <FormControl sx={{ mb: 2 }}>
                                      <InputLabel id="demo-simple-select-label">Select Internel Page</InputLabel>
                                      <Select
                                        sx={{ padding: 1.5, width: 200 }}
                                        name="internelLink"
                                        label="Select Internel Page"
                                        defaultValue={e.internelLink.value || ''}
                                        onChange={(f) => handleChange(f, i)}
                                      >
                                        {previousData && previousData.map((data, index) => {
                                          return (
                                            <MenuItem value={`/${data.RoutePath.value}` || ''} key={index}>{data.MenuName.value}</MenuItem>
                                          )
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                }</>

                                <>{(e.linkType === "Externel" && e.contentType === "Link") &&
                                  <Grid item xs={2}>
                                    <MDInput
                                      name="externelLink"
                                      label="Enter externel link"
                                      value={e.externelLink}
                                      onChange={(f) => handleChange(f, i)}
                                      sx={{ mb: 2, width: 200 }}
                                    />
                                  </Grid>
                                }</>

                                <>{(e.contentType === "Title" ||
                                  e.contentType === "Subtitle" ||
                                  e.contentType === "Description" ||
                                  e.contentType === "Link") &&
                                  <>
                                    <Grid item xs={2}>
                                      <MDInput
                                        name="generelText"
                                        label="Enter content"
                                        value={e.generelText}
                                        onChange={(f) => handleChange(f, i)}
                                        sx={{ mb: 2, width: 200 }} />
                                    </Grid>

                                    <Grid item xs={1}>
                                      <FormControl sx={{ mb: 2, width: 130, ml: -4 }}>
                                        <InputLabel id="demo-simple-select-label">Select Icon</InputLabel>
                                        <Select
                                          sx={{ padding: 1.5 }}
                                          name="icon"
                                          label="Select Icon"
                                          defaultValue={e.icon.value || ''}
                                          onChange={(f) => handleChange(f, i)}
                                        >
                                          <MenuItem value="facebook" >Facebook</MenuItem>
                                          <MenuItem value="twitter" >Twitter</MenuItem>
                                          <MenuItem value="linkdin" >Linkdin</MenuItem>
                                          <MenuItem value="github" >GitHub</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={1}>
                                      <MDInput
                                        name="sequence"
                                        label="Sequence No."
                                        value={e.sequence}
                                        onChange={(f) => handleChange(f, i)}
                                        sx={{ mb: 2, width: 100 }} />
                                    </Grid>
                                  </>
                                }</>



                              </Fragment>

                            </Grid>
                          }
                        </>

                      )
                    })
                  }





                </Grid>
              )
            })
          }
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            {
              formData.length > 0 &&
              <MDButton
                variant="gradient"
                color="secondary"
                sx={{ float: 'right', width: '20%', mr: -7 }}
                onClick={saveData}
              >
                Save
              </MDButton>
            }
          </Grid>
        </Grid>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Successfully Added !
          </Alert>
        </Snackbar>
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
FooterPageContent.defaultProps = {
  children: "",
};

// Typechecking props for the Header
FooterPageContent.propTypes = {
  children: PropTypes.node,
};

export default FooterPageContent;
