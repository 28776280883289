import { useState, useEffect, useContext, Fragment } from "react";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React Components
import MDButton from "components/MDButton";
import breakpoints from "assets/theme/base/breakpoints";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Images
import backgroundImage from "assets/images/bg-profile.jpeg";
import { UserDbContent } from "context/UserDbContext";
import NavigationForm from "../components/NavigationForm";
import { GQL_GET_ROUTES } from "settings/dynamo-db-helper";
import { getDynamoPagedItems } from "settings/dynamo-db-helper";
import { GetDbInfo } from "../../../helpers/AuthHelper";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import CustomLoader from "../components/CustomLoader";

const ADD_QUERY = gql`
mutation($request: DynamoObjectInput!){
  createObject(request: $request){
    success
  }
}`

const GET_QUERY = gql`
query($request: DynamoObjectListRequest!){
  objectList(request: $request){
    page
    pageSize
    totalCount
    items{
      objectId
      tableId
      name
      properties{
        propertyId
        name
        value
      }
    }
  }
}`

const UPDATE_QUERY = gql`mutation($request: DynamoCellBulkUpdateInput!){
  updateBulkCellValue(request: $request){
    success
    message
    data{
      dynamoCells{
        id
        value
      }
    }
  }
}`

function NavigationDynamic() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  let rowsInput = {
    menuName: { propertyId: "", value: "" },
    parent: { propertyId: "", value: "" },
    routePath: { propertyId: "", value: "" },
    navSerial: { propertyId: "", value: "" },
    componentName: { propertyId: "", value: "" }
  }
  const [formData, setFormData] = useState([rowsInput]);
  const [previousData, setPreviousData] = useState([]);
  const [open, setOpen] = useState(false);

  const { database, tables } = useContext(UserDbContent)

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [addFormData,
    { loading: posting, error: postError, data: postData },
  ] = useMutation(ADD_QUERY, {
    onCompleted: (data) => {
      let result = data && data.createObject && data.createObject.success;
      if (result) {
        setOpen(true);
        refetch();
      } else {
        alert("Failed");
      }
    },
    onError: (e) => {
      alert(e.message);
    },
  });

  const [getRoutesInfo, { refetch, loading }] = useLazyQuery(GET_QUERY, {
    variables: {
      request: {
        pageSize: 1000,
        tableName: "routes",
        databaseId: GetDbInfo().dbId,
        properties: GQL_GET_ROUTES
      },
    },
    onCompleted: (d) => {
      if (d.objectList.items.length !== 0) {
        let items = getDynamoPagedItems(d);
        // let items = d && d.objectList && d.objectList.items.map(e => e.properties);
        let st = items.sort((a, b) => parseInt(a.NavSerial.value) - parseInt(b.NavSerial.value));
        setPreviousData(st);
        const prevRowsInput = st.map((e) => {
          return {
            menuName: e.MenuName,
            parent: e.Parent,
            routePath: e.RoutePath,
            navSerial: e.NavSerial,
            componentName: e.ComponentName,
            objectId: e.objectId
          }
        })
        setFormData(prevRowsInput);
      }
      else {
        setFormData([rowsInput])
      }
    },
    onError: (e) => {
      alert("error");
    },
  });

  useEffect(() => {
    getRoutesInfo();
  }, [])

  const isValidate = (e) => {
    let validate = false;
    previousData && previousData.map((data, index) => {
      if (data.MenuName === e) {
        validate = true;
      }
    })
    return validate;
  }

  const [updateFormData, { loading: updating, error: updateError }] = useMutation(UPDATE_QUERY, {
    onCompleted: (data) => {
      let result = data && data.updateBulkCellValue && data.updateBulkCellValue.success;
      if (result) {
        setOpen(true);
        refetch();
      } else {
        alert("Update Failed");
      }
    },
    onError: (e) => {
      alert(e.message);
    },
  });

  const saveData = () => {
    const routesTable = tables.find(e => e.name == "routes")
    const routesColumn = routesTable.columns;

    let updatedData = []
    let addData = []
    let flag = false;
    let flag2 = false;

    for (let i = 0; i < formData.length; i++) {
      let newObject = formData[i]

      let keys = Object.keys(newObject)

      if (!keys.includes("objectId")) {
        if (isValidate(newObject.menuName.value)) {
          alert("Menu Name already exist")
        }
        else {
          flag = true;
          addData.push(
            {
              propertyId: routesColumn.find(p => p.name === "MenuName").id,
              value: newObject.menuName.value
            },
            {
              propertyId: routesColumn.find(p => p.name === "Parent").id,
              value: newObject.parent.value
            },
            {
              propertyId: routesColumn.find(p => p.name === "RoutePath").id,
              value: newObject.routePath.value
            },
            {
              propertyId: routesColumn.find(p => p.name === "NavSerial").id,
              value: newObject.navSerial.value
            },
            {
              propertyId: routesColumn.find(p => p.name === "ComponentName").id,
              value: newObject.componentName.value
            }
          );
        }
      }

      else {
        flag2 = true;
        for (let j = 0; j < keys.length; j++) {
          let prp = keys[j]

          if (keys[j] !== 'objectId') {
            updatedData.push({
              columnId: newObject[prp].propertyId,
              value: newObject[prp].value
            })
          }
        }
      }
    }
    if (flag === true) {
      addFormData({
        variables: {
          request: {
            tableId: routesTable.id,
            properties: addData
          }
        }
      })
    }
    if (flag2 === true) {
      updateFormData({
        variables: {
          request: {
            dynamoCells: updatedData
          }
        }
      })
    }
  }

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);
    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const rowsInputValue = [...formData];
    rowsInputValue[index][name].value = value;
    setFormData(rowsInputValue);
  };


  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <MDBox>
          <MDBox lineHeight={0} sx={{ mb: 5, textAlign: "center" }}>
            <MDTypography variant="h5">Create Dynamic Page</MDTypography>
            <MDTypography variant="button" color="text">
              Mandatory informations
            </MDTypography>
          </MDBox>
        </MDBox>
        <Grid container direction="column" alignItems="center" >
          {
            loading ?
              <CircularProgress color="success" /> :
              formData.map((e, i) => {
                return (
                  <Fragment key={i}>
                    <Grid item xs={12} sm={12} md={12} sx={{ width: "50%" }}>
                      <NavigationForm prevData={previousData} contentValue={e} onChangeEvent={handleChange} index={i} />
                    </Grid>
                  </Fragment>
                )
              })
          }
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              Successfully Added !
            </Alert>
          </Snackbar>
        </Grid>
        <Grid container direction="row">
          <Grid item md={6} sx={{ justifyItems: "flex-end", justifyContent: "flex-end", alignContent: "flex-end", alignItems: "flex-end" }}>
            {
              updating || posting ?
                <CircularProgress sx={{ float: "right" }} color="success" /> :
                <MDButton
                  variant="gradient"
                  color="secondary"
                  sx={{ mt: 3, float: "right" }}
                  onClick={saveData}
                >
                  Save
                </MDButton>
            }
          </Grid>
          <Grid item md={6}>
            <MDButton
              variant="gradient"
              color="primary"
              sx={{ mt: 3, ml: 3 }}
              onClick={() => setFormData([...formData, rowsInput])}
            >
              Add New
            </MDButton>
          </Grid>
        </Grid>
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
NavigationDynamic.defaultProps = {
  children: "",
};

// Typechecking props for the Header
NavigationDynamic.propTypes = {
  children: PropTypes.node,
};

export default NavigationDynamic;
