import React, { useState } from "react";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from '@mui/icons-material/Delete';
import UploadImageModal from "./UploadImageModal";

function SliderForm(props) {
  const [open, setOpen] = useState(false);


  const handleOpenModal = () => {
    // setTabValue(0);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    // setSelectedImage("");
  };

  return (
    <>
      <MDTypography variant="h6" sx={{ mt: 5, mb: 2 }}>
        Image {props.index + 1}
      </MDTypography>
      <Grid direction="row" container>
        <Grid item md={4} sm={12}>
          <MDInput
            name="contentSerial"
            value={props.contentValue.contentSerial.value}
            label="Content Serial"
            sx={{ mb: 2, width: "90%" }}
            onChange={(e) => props.onChangeEvent(e, props.index)}
          />
        </Grid>
        <Grid item md={8} sm={12}>
          <MDInput
            name="title"
            label="Header"
            value={props.contentValue.title.value}
            fullWidth
            sx={{ mb: 2 }}
            onChange={(e) => props.onChangeEvent(e, props.index)}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <FormControl sx={{ mb: 2, width: "90%" }}>
            <InputLabel id="demo-simple-select-label">Select Page Link</InputLabel>
            <Select
              sx={{ padding: 1.5 }}
              name="sliderTextLink"
              value={props.contentValue.sliderTextLink.value}
              label="Select Page Link"
              onChange={(e) => props.onChangeEvent(e, props.index)}
            >
              <MenuItem value="/home">Home</MenuItem>
              <MenuItem value="/services">Services</MenuItem>
              <MenuItem value="/our-team">Teams</MenuItem>
              <MenuItem value="/technology">Technologies</MenuItem>
              <MenuItem value="/gallery">Gallery</MenuItem>
              <MenuItem value="/career">Career</MenuItem>
              <MenuItem value="/about-us">About Us</MenuItem>
              <MenuItem value="/contact-us">Contact Us</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={8} sm={12}>
          <MDInput
            name="subTitle"
            value={props.contentValue.subTitle.value}
            label="Subtitle"
            fullWidth
            sx={{ mb: 2 }}
            onChange={(e) => props.onChangeEvent(e, props.index)}
          />
        </Grid>
        <Grid item md={12} sm={12}>
          <MDButton
            variant="gradient"
            color="info"
            sx={{ width: "20%", mt: 3, mb: 3 }}
            onClick={handleOpenModal}
          >
            Upload <UploadIcon sx={{ marginLeft: "15%" }} />
          </MDButton>
          {
              props.hasImage !=="" &&
              <MDButton
                  variant="gradient"
                  color="error"
                  sx={{ width: "20%", mt: 3, mb: 3, ml:2 }}
                  onClick={() => props.handleImageRemove(props.index)}
              >
                  Remove <DeleteIcon sx={{ marginLeft: "15%" }} />
              </MDButton>
          }
        </Grid>
      </Grid>

      <UploadImageModal
        open={open}
        setOpen = {setOpen}
        handleClose={handleClose}
        onFileChange = {props.onFileChange}
        selectedFor={props.index}
      />
    </>
  );
}

export default SliderForm;
