import React from 'react'
import MDInput from "components/MDInput";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid } from '@mui/material';


function NavigationForm(props) {
    const { prevData } = props;

    return (
        <>
            <InputLabel style={{marginInline:"45%", marginBottom:20, color:"black"}}><h4>Menu: {props.index+1}</h4></InputLabel>
            <hr style={{marginBottom:20}}/>
            <Grid direction="row" spacing={3} container>
                <Grid item md={6} sm={12}>
                    <FormControl sx={{ mb: 2, width:"100%" }}>
                        <InputLabel id="demo-simple-select-label">Parent</InputLabel>
                        <Select
                            sx={{ padding: 1.5 }}
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="parent"
                            label="Parent"
                            value={props.contentValue.parent.value}
                            onChange={(e) => props.onChangeEvent(e, props.index)}
                        >
                            {prevData && prevData.map((data,index) => {
                                return(
                                    <MenuItem value={data.MenuName.value} key={index}>{data.MenuName.value}</MenuItem>
                                 )
                            })} 
                        </Select>
                    </FormControl>
                </Grid>
                
                <Grid item md={6} sm={12}>
                    <MDInput name="navSerial" value={props.contentValue.navSerial.value} label="Serial" sx={{ mb: 2 }} fullWidth onChange={(e) => props.onChangeEvent(e, props.index)} />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item md={12} sm={12}>
                    <MDInput name="menuName" value={props.contentValue.menuName.value} label="Menu Name*" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item md={12} sm={12}>
                    <MDInput name="routePath" value={props.contentValue.routePath.value} label="Route Path*" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} />
                </Grid>
            </Grid>
            
            <Grid container>
                <Grid item md={12} sm={12}>
                    <MDInput name="componentName" value={props.contentValue.componentName.value} label="Component Name*" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} />
                </Grid>
            </Grid>
        </>
    );
}

export default NavigationForm;