import { CircularProgress } from '@mui/material';
import React from 'react';

const CustomLoader = () => {
  return (
    <div style={{marginLeft:"48%"}}>
        <CircularProgress color='success' />
    </div>
  )
}

export default CustomLoader;