import React from 'react'
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import GalleryPageContent from './components/GalleryPageContent';



const GalleryPage = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <GalleryPageContent />
        </DashboardLayout>
    )
}

export default GalleryPage