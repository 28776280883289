import React from 'react'
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import NavigationDynamic from './NavigationContent';



const NavigationPage = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <NavigationDynamic />
        </DashboardLayout>
    )
}

export default NavigationPage