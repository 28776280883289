
import React, { useState, useEffect, useContext, useMemo } from "react";
// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate  } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
//import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

import { UserDbContent } from "./context/UserDbContext";
import {gql, useLazyQuery, useQuery} from '@apollo/client';

import Login from "./layouts/pages/auth/SignIn"
import {SetAuthToken, GetAuthToken, GetDbInfo} from "./helpers/AuthHelper";

// Images
import { GQL_GET_ROUTES } from "settings/dynamo-db-helper";
import { getDynamoRoutesItems } from "settings/dynamo-db-helper";
import { getRoutesFormat } from "settings/dynamo-db-helper";
import { AuthContext } from "./context/AuthContext";

import {DB_QUERY, TABLE_LIST_QUERY, ROUTE_QUERY} from "./settings/GraphQLConstants";

export default function App() {
    const [controller, dispatch] = useMaterialUIController();
    const {
        miniSidenav,
        direction,
        layout,
        openConfigurator,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    //const [rtlCache, setRtlCache] = useState(null)

    const { isAuthenticated, setAuthValue, authenticate, getUserInfo } = useContext(AuthContext)
    let navigate = useNavigate();
    const { pathname } = useLocation();

    //ApiContextState
    const [database, setDatabase] = useState(null);
    const [tables, setTables] = useState([])
    //const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [routes, setRoutes] = useState([])
    //ApiContextState
    let userName = getUserInfo() && getUserInfo().database && getUserInfo().database.name && getUserInfo().database.name.split(".");

    function dispatchUserBdContext(actionType, payload){
        switch (actionType) {
            case "SUCCESS_GET_DB":
                setDatabase(payload)
                return
            case "SUCCESS_GET_TABLES":
                setTables(payload)
                return;
            case "SUCCESS_GET_ROUTES":
                setRoutes(payload)
                return;
            default:
                return;
        }
    }

    useEffect(() => {
        if(isAuthenticated){
            getTables({
                variables:{
                    "request": {
                        "databaseId": GetDbInfo().dbId
                    }
                }
            })
        }
    },[isAuthenticated])

    useEffect(() => {
        if(tables && tables.find(a => a.name === 'routes')){
            getDbRoutes()
        }
    },[tables])

    const [getDbRoutes] = useLazyQuery(ROUTE_QUERY, {
        variables: {
            request: {
                pageSize: 1000,
                tableName: "routes",
                databaseId: GetDbInfo().dbId,
                properties: GQL_GET_ROUTES,
            },
        },
        onCompleted:(routeData) =>{
            let data = getDynamoRoutesItems(routeData)

            let simplifiedData = getRoutesFormat(data);

            if(simplifiedData){
                dispatchUserBdContext("SUCCESS_GET_ROUTES", simplifiedData)
            }
        },
        onError:(simplifiedData) =>{
            dispatchUserBdContext("SUCCESS_GET_ROUTES", simplifiedData)
        }
    })

    const [getTables] = useLazyQuery(TABLE_LIST_QUERY, {
        onCompleted:(tData) =>{
            if(tData && tData.tableList && tData.tableList.items){
                dispatchUserBdContext("SUCCESS_GET_TABLES", tData.tableList.items)
            }
        },
        onError:() => {
            dispatchUserBdContext("SUCCESS_GET_TABLES", [])
        }
    })

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const getRoutes = (allRoutes) =>{
        if(Array.isArray(allRoutes)){
            return allRoutes.map((route) => {
                if (route.collapse) {
                    return getRoutes(route.collapse);
                }

                if (route.route) {
                    return <Route exact path={route.route} element={route.component} key={route.key} />;
                }

                return null;
            });
        }else{
            return []
        }
    }

    const configsButton = (
        <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.25rem"
            height="3.25rem"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{ cursor: "pointer" }}
            onClick={handleConfiguratorOpen}
        >
            <Icon fontSize="small" color="inherit">
                settings
            </Icon>
        </MDBox>
    );

    return(
        <>
            <UserDbContent.Provider value={{database, tables, routes, dispatchUserBdContext}}>
                <ThemeProvider theme={darkMode ? themeDark : theme}>
                    <CssBaseline />
                    {layout === "dashboard" && (
                        <>
                            <Sidenav
                                color={sidenavColor}
                                //brand={}
                                brandName={userName && userName[1] !== null ? userName[1] : "Dynamic Website"}
                                routes={routes}
                                onMouseEnter={handleOnMouseEnter}
                                onMouseLeave={handleOnMouseLeave}
                            />
                            <Configurator />
                            {configsButton}
                        </>
                    )}
                    {layout === "vr" && <Configurator />}
                    <Routes>
                        {!isAuthenticated &&
                            <>
                                <Route path="/login" element={<Login/>} />
                                <Route path="*" element={<Navigate to="/login" />} />
                            </>
                        }
                        {isAuthenticated &&
                            <>
                                {getRoutes(routes)}
                                <Route path="*" element={<Navigate to="/dashboards/analytics" />} />
                            </>
                        }
                    </Routes>
                </ThemeProvider>
            </UserDbContent.Provider>
        </>
    )
}