import React, { useState } from 'react'
import MDInput from "components/MDInput";
import MDDropzone from "components/MDDropzone";
import MDTypography from 'components/MDTypography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid } from '@mui/material';
import MDButton from 'components/MDButton';
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from '@mui/icons-material/Delete';
import UploadImageModal from './UploadImageModal';


function PageContentForm(props) {
    const [open, setOpen] = useState(false);


    const handleOpenModal = () => {
      // setTabValue(0);
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
      // setSelectedImage("");
    };

    return (
        <>
            <MDTypography variant="h6" sx={{ mt: 5, mb: 2 }}>Section {props.index + 1}</MDTypography>
            <Grid direction="row" container spacing={3}>
                <Grid item md={4} sm={6} xs={12}>
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">Column Size</InputLabel>
                        <Select
                            sx={{ padding: 1.5 }}
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="gridSize"
                            label="Column Size"
                            value={(props.contentValue).gridSize.value || ""}
                            onChange={(e) => props.onChangeEvent(e, props.index)}
                        >
                            {[...Array(12)].map((nav, navi) => {
                                return (
                                    <MenuItem value={(navi + 1) + ""} key={navi}>{navi + 1}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">Column Position</InputLabel>
                        <Select
                            sx={{ padding: 1.5 }}
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="gridContainerProps"
                            label="Column Position"
                            value={(props.contentValue).gridContainerProps.value || ""}
                            onChange={(e) => props.onChangeEvent(e, props.index)}
                        >
                            <MenuItem value={"center"}>Center</MenuItem>
                            <MenuItem value={"flex-start"}>Flex Start</MenuItem>
                            <MenuItem value={"flex-end"}>Flex End</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <MDInput name="contentSerial" value={(props.contentValue).contentSerial.value || ""} label="Content Serial" sx={{ mb: 2 }} fullWidth onChange={(e) => props.onChangeEvent(e, props.index)} />
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item md={4} sm={5} xs={12}>
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">Header Type</InputLabel>
                        <Select
                            sx={{ padding: 1.5 }}
                            autoWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={(props.contentValue).headerType.value || ""}
                            name="headerType"
                            label="Header Type"
                            onChange={(e) => props.onChangeEvent(e, props.index)}
                        >
                            {[...Array(6)].map((nav, navi) => {
                                return (
                                    <MenuItem value={('h' + (navi + 1)) + ""}>{'h' + (navi + 1)}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={8} sm={7} xs={12}>
                    <MDInput name="title" label="Header" value={(props.contentValue).title.value || ""} fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} />
                </Grid>
            </Grid>

            {/* <MDInput name="subtitle" label="Sub Header" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} /> */}
            <MDInput multiline rows={5} name="description" value={(props.contentValue).description.value || ""} label="Description" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} />
            {/* <MDInput name="imageurl" type="file" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} /> */}
            <Grid container spacing={3}>
                <Grid item md={8} sm={6} xs={12}>
                <MDButton
                    variant="gradient"
                    color="info"
                    sx={{ width: "20%", mb: 3 }}
                    onClick={handleOpenModal}
                >
                    Upload <UploadIcon sx={{ marginLeft: "15%" }} />
                </MDButton>
                {
                    props.hasImage !=="" &&
                    <MDButton
                        variant="gradient"
                        color="error"
                        sx={{ width: "20%", mb: 3, ml:8 }}
                        onClick={() => props.handleImageRemove(props.index)}
                    >
                        Remove <DeleteIcon sx={{ marginLeft: "15%" }} />
                    </MDButton>
                }
                    {/* <MDInput name="imageurl" value={(props.contentValue).imageurl.value || ""} label="Image Link" fullWidth sx={{ mb: 2 }} onChange={(e) => props.onChangeEvent(e, props.index)} /> */}
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <FormControl sx={{ mb: 2 , width:"100%"}}>
                        <InputLabel id="simple-select-label">Content Type</InputLabel>
                        <Select
                            sx={{ padding: 1.5 }}
                            fullWidth
                            labelId="simple-select-label"
                            id="simple-select"
                            name="contentType"
                            label="Content Type"
                            value={(props.contentValue).contentType && (props.contentValue).contentType.value}
                            onChange={(e) => props.onChangeEvent(e, props.index)}
                        >
                            <MenuItem value={"1"}>General</MenuItem>
                            <MenuItem value={"2"}>Style Card</MenuItem>
                            <MenuItem value={"3"}>Card</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <UploadImageModal
                open={open}
                setOpen = {setOpen}
                handleClose={handleClose}
                onFileChange = {props.onFileChange}
                selectedFor={props.index}
            />
        </>
    );
}

export default PageContentForm;