// import React, { createContext } from "react";
//
// export const AuthContext = createContext(
//     {
//         isAuthenticated: false,
//     });


import React, {createContext, useEffect, useState} from "react";
import { gql } from "@apollo/client";
import {useLazyQuery} from "@apollo/client";
import { Auth } from "../settings/common";
import {SetDbInfo} from "../helpers/AuthHelper";
//import {useRouter} from "next/router";
// import { initializeApollo } from "../utils/apollo";

// const AUTH_QUERY = gql`query($query: QueryParams!){userQuery{
//     authorize(query: $query){
//       expiration
//       issuer
//       refreshToken
//       token
//       requestToken
//       requestTokenHeader
//       roles
//       me{
//         id
//         fullName
//         email
//         phoneNumber
//         address
//         sex
//         userAccounts{
//           id
//           accountId
//         }
//       }
//     }
//   }
// }`;

const AUTH_QUERY = gql`query($query: AuthorizeRequest!){
  authorize(request: $query){
    token
    me{
      database{
        id
        name
      }
    }
  }
}`

const GET_DB_QUERY = gql`query($request: DynamoDatabaseGetRequest!){
  database(request: $request){
    id
    name
    active
  }
}`

const isValidToken = () => {
    let isAuth = false;
    debugger
    const auth_data = Auth.get();
    // if (auth_data) {
    //     const expiration = new Date(auth_data.expiration);
    //     isAuth = expiration >= new Date();
    // }
    auth_data ? isAuth = true: isAuth = false
    return isAuth;
}

const getAuthData = () => {
    const auth_data = Auth.get();
    let data = {
        token : "",
        role : []
    }
    if(auth_data){
        data.token = auth_data.token,
            data.role = auth_data.roles
    }
    return data;
}


function AuthProvider(props){
    const [isAuthenticated, setIsAuthenticated] = useState(isValidToken());
    const [loginError, setLoginError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dbId, setDbId] = useState("")
    const [dbName, setDbName] = useState("")


    // useEffect(() => {
    //     if(isAuthenticated){
    //         debugger
    //         let data = Auth.get();
    //         if(data){
    //             getDbInfo({
    //                 variables:{
    //                     "request": {
    //                         "databaseName": data.me.database.name
    //                     }
    //                 }
    //             })
    //         }
    //
    //     }
    // },[isAuthenticated])


    async  function authenticate({ username, password }, cb) {
        debugger
        setLoading(true)
        authorize({
            variables: {
                "query":{
                    "userName": username,
                    "password": password
                }
            }
        })
    }



    const [authorize] = useLazyQuery(AUTH_QUERY, {
        onCompleted: (d) => {
            let data = d.authorize
            debugger
            if(data){
                setIsAuthenticated(true);
                Auth.set(data);
                SetDbInfo(data.me.database.name, data.me.database.id)
            }



            // let dbInfo = d && d.database
            // if(dbInfo){
            //     let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"
            //     SetDbInfo(dbInfo.name, dbInfo.id)
            //     setAuthValue(token)
            //     navigate("/dashboards/analytics")
            // }
        },
        onError: (e) => {
            console.log(e)
            alert("Unable to login!!!!")
        },
        fetchPolicy: "network-only"
    })


    const [getDbInfo] = useLazyQuery(GET_DB_QUERY, {
        onCompleted: (d) => {
            let dbInfo = d && d.database
            if(dbInfo){
                //let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"
                SetDbInfo(dbInfo.name, dbInfo.id)
                //setAuthValue(token)
                navigate("/dashboards/analytics")
            }
        },
        onError: (e) => {
            console.log(e)
            alert("Unable to login!!!!")
        },
        fetchPolicy: "network-only"
    })


    // const [requestAuth, { loading, data }] = useLazyQuery(AUTH_QUERY, {
    //     onCompleted: () => {
    //       setIsAuthenticated(true);
    //       setLoginError(false);
    //     }, onError: (e) => {
    //       setLoginError(true)
    //     }
    // });

    // if (data) {
    //     Auth.set(data);
    // }

    function checkToken() {
        setIsAuthenticated(isValidToken());
    }

    function signout(cb) {
        Auth.remove();
        setIsAuthenticated(false);
        // if(router.pathname!== "/"){
        //     router.push("/");
        // }

    }

    function getUserInfo(){
        var data = Auth.get();
        return (data || {me: null}).me;
    }

    return(
        <AuthContext.Provider
            value={{
                isAuthenticated,
                authenticate,
                loading,
                loginError,
                checkToken,
                signout,
                getAuthData,
                getUserInfo
            }}
        >
            <>{props.children}</>
        </AuthContext.Provider>
    )
}
export default AuthProvider;

export const AuthContext = createContext({});