import {gql} from "@apollo/client";


export const DB_QUERY = gql`query($request: DynamoDatabaseGetRequest!){
  database(request: $request){
    id
    name
    active
  }
}`
export const TABLE_LIST_QUERY = gql`query($request: DynamoTableGetListRequest!){
  tableList(request: $request){
    page
  pageSize
  items{
      id
      name
      columns{
        id
        name
      }
    }
  }
}`

export const ROUTE_QUERY = gql`
query($request: DynamoObjectListRequest!){
  objectList(request: $request){
  page
  pageSize
  totalCount
  items{
    objectId
    tableId
    name
      properties{
        name
        value
      }
    }
  }
  }`