import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import MDInput from "components/MDInput";
import { Alert, Grid, Snackbar } from "@mui/material";
import MDButton from "components/MDButton";
import Modal from "@mui/material/Modal";
import TabPanel from "layouts/pages/components/TabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { GetDbInfo } from "helpers/AuthHelper";
import CustomLoader from "./CustomLoader";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 200,
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

const GET_ALL_FILE = gql`
  query ($request: DynamoFileFilterInput!) {
    uploadedItems(request: $request) {
      totalCount
      page
      items {
        website
        blobUrl
      }
    }
  }
`;

const UPLOAD_QUERY = gql`
  mutation ($request: UploadFileInput!) {
    uploadFile(request: $request) {
      success
      code
      data {
        blobUrl
      }
    }
  }
`;

const DELETE_QUERY = gql`
  mutation ($request: DynamoModifyBlobInput!) {
    deleteBlobFiles(request: $request) {
      data {
        containerName
        files
      }
    }
  }
`;

export default function UploadImageModal(props) {
  const { open, handleClose } = props;

  const [uploadedItems, setUploadedItems] = useState();
  const [page, setPage] = useState(1);
  const [tabValue, setTabValue] = useState(0);
  const [selectedImage, setSelectedImage] = useState();
  const [imageEvent, setImageEvent] = useState();
  const [totalCount, setTotalCount] = useState();
  const [openDelete, setOpenDelete] = useState();
  const [successDelete, setSuccessDelete] = useState(false);
  const [successUpload, setSuccessUpload] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState();
  const [customPageSize, setCustomPageSize] = useState(10)

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSuccessDelete = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessDelete(false);
  };
  const handleSuccessUpload = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessUpload(false);
  };

  const handleDeleteModalOpen = (data) => {
    setOpenDelete(true);
    setSelectedDelete(data);
  };

  const [getAllImages, { loading, refetch }] = useLazyQuery(GET_ALL_FILE, {
    fetchPolicy: "network-only",
    variables: {
      request: {
        page: page,
        pageSize: customPageSize,
        ascending: true,
        orderKey: "id",
        container: GetDbInfo().dbName,
      },
    },
    onCompleted: (d) => {
      if (d) {
        let items = d && d.uploadedItems && d.uploadedItems.items;
        let totalCount = d && d.uploadedItems && d.uploadedItems.totalCount;
        setTotalCount(totalCount);
        setUploadedItems(items);
      }
    },
    onError: () => {
      console.log("Get Uploaded items failed");
    },
  });

  const [deleteImage, { loading: deleting }] = useMutation(DELETE_QUERY, {
    onCompleted: (d) => {
      if (d) {
        setSuccessDelete(true);
        handleCloseDelete();
        refetch();
      }
    },
    onError: () => {
      console.log("Can't delete image");
    },
  });

  const handleDeleteImage = (fileName) => {
    let name = fileName.split("/")[4];
    deleteImage({
      variables: {
        request: {
          containerName: "www-vehiclesolution-net",
          files: [name],
        },
      },
    });
  };

  useEffect(() => {
    if (tabValue == 1) {
      getAllImages();
    }
  }, [tabValue]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleImageSelect = (event, customUrl) => {
    setSelectedImage(customUrl);
    setImageEvent(event);
  };

  const setSelected = () => {
    props.onFileChange(imageEvent, selectedImage, props.selectedFor);
    resetState();
  };

  const resetState = () => {
    props.setOpen(false);
    setSelectedImage("");
  };

  const [uploadFileMutation] = useMutation(UPLOAD_QUERY, {
    onCompleted: (d) => {
      if (d) {
        setSuccessUpload(true);
        refetch();
      }
    },
    onError: (e) => {
      console.log(e);
    },
  });

  async function handleUpload(e) {
    let file = e.target.files[0];
    function getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }

    uploadFileMutation({
      variables: {
        request: {
          file: file,
          website: GetDbInfo().dbName,
        },
      },
    });
  }

  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose}>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <Box sx={{ borderColor: "divider" }}>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Upload" {...a11yProps(0)} />
                <Tab label="Select Image" {...a11yProps(1)} />
              </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
              <MDInput
                name="imageurl"
                type="file"
                fullWidth
                sx={{ mb: 2 }}
                onChange={(e) => handleUpload(e)}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Grid container columnSpacing={5}>
                {loading ? (
                  <CustomLoader />
                ) : (
                  uploadedItems &&
                  uploadedItems.map((a, i) => {
                    return (
                      <Grid item key={i}>
                        <Grid style={{ cursor: "pointer" }}>
                          {/* <p style={{ marginBottom: -25 }}>Image {i + 1}</p> */}
                          <DeleteIcon
                            sx={{ float: "right" }}
                            color="error"
                            fontSize="medium"
                            onClick={() => handleDeleteModalOpen(a.blobUrl)}
                          />
                        </Grid>
                        <Grid>
                          <img
                            onClick={(e) => handleImageSelect(e, a.blobUrl)}
                            height="200px"
                            width="auto"
                            name="imageurl"
                            border="1px solid black"
                            src={a.blobUrl}
                            style={{
                              border: selectedImage === a.blobUrl ? "3px solid blue" : null,
                            }}
                          />
                        </Grid>
                      </Grid>
                    );
                  })
                )}

                <Grid container>
                  <Grid item md={4} sm={4} xs={4}>
                    <Stack spacing={2} mt={5} sx={{ml:10}}>
                      <Pagination count={Math.ceil(totalCount / customPageSize)} page={page} onChange={handleChange} />
                    </Stack>
                  </Grid>
                  <Grid item md={8} sm={8} xs={8}> 
                    <Stack>
                      <FormControl sx={{ width: "10%" , mt:4, ml:-10}}>
                        <InputLabel id="demo-simple-select-label">Display</InputLabel>
                        <Select
                          sx={{ padding: 1.5 }}
                          name="filter"
                          value={customPageSize}
                          // defaultValue={10}
                          label="Display"
                          onChange={(e) => setCustomPageSize(e.target.value)}
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={15}>15</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={30}>30</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>


              <DialogActions>
                <MDButton
                  variant="gradient"
                  color="error"
                  onClick={() => handleClose(resetState())}
                  sx={{ marginInline: 2 }}
                >
                  Cancel
                </MDButton>
                <MDButton onClick={setSelected} variant="gradient" color="success">
                  Select
                </MDButton>
              </DialogActions>
            </TabPanel>
          </Box>
        </DialogContent>
      </Dialog>

      <Modal
        open={openDelete}
        onClose={() => handleCloseDelete()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          Are You Sure ?
          <Grid container sx={{ marginTop: 10, justifyContent: "flex-end" }}>
            <MDButton
              variant="gradient"
              color="secondary"
              onClick={() => handleCloseDelete()}
              sx={{ marginRight: 2 }}
            >
              Cancel
            </MDButton>
            <MDButton
              variant="gradient"
              disabled={deleting ? true : false}
              color="error"
              onClick={() => handleDeleteImage(selectedDelete)}
            >
              {deleting ? "Deleting" : "Delete"}
            </MDButton>
          </Grid>
        </Box>
      </Modal>

      <Snackbar
        open={successDelete}
        autoHideDuration={6000}
        onClose={handleSuccessDelete}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSuccessDelete} severity="success" sx={{ width: "100%" }}>
          Successfully Deleted !
        </Alert>
      </Snackbar>
      <Snackbar
        open={successUpload}
        autoHideDuration={6000}
        onClose={handleSuccessUpload}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSuccessUpload} severity="success" sx={{ width: "100%" }}>
          Successfully Uploaded !
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
