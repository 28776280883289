import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { BrowserRouter } from "react-router-dom";
import { MaterialUIControllerProvider } from "context";
import { API_URL } from "./settings/ApiUrl";
import { Auth} from "./settings/common";

//context
import AuthProvider from "./context/AuthContext";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const auth = Auth.get()
const token = auth ? auth.token : ""

const uploadLink =() =>
{
  return createUploadLink({
    uri: API_URL,
    headers: {
      authorization: `Bearer ${token}`
    }
  })
}

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: uploadLink()
});

ReactDOM.render(
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <ApolloProvider client={client}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </ApolloProvider>
      </MaterialUIControllerProvider>
    </BrowserRouter>,
    document.getElementById("root")
);